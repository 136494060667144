import React from 'react';
import { Link } from 'gatsby';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import settings from '../../settings';


const Footer = () => {
  const BMWFooterLogo = `${settings.IMAGES_BASE_URL}/images/logos/moto.png`;
  const MiniFooterLogo = `${settings.IMAGES_BASE_URL}/images/logos/MINI.png`;

  return (
    <footer>
      <div className="footer__top">
        <div className="container">
          <p className="available">Also available</p>
          <div className="other-links">
            <a className="footer__bmw" href="/motorrad" rel="noopener noreferrer" target="_blank">
              <LazyLoadImage effect="blur" src={BMWFooterLogo} alt="BMW Footer Logo" />
              <p>BMW U.S. Rider Academy</p>
            </a>
            <a className="footer__mini" href="/mini" rel="noopener noreferrer" target="_blank">
              <LazyLoadImage effect="blur" src={MiniFooterLogo} alt="MINI Footer Logo" />
              <p>MINI Driving Experience</p>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-one-third">
            <ul>
              <li>
                <Link to="/instructors">Instructors</Link>
              </li>
              <li>
                <Link to="/schoolfaq">School FAQ</Link>
              </li>
              <li>
                <Link to="/deliveryfaq">Delivery FAQ </Link>
              </li>
              <li>
                <Link to="/partners">Partners</Link>
              </li>
              <li>
                <Link to="/hotelpartners">Hotel Partners</Link>
              </li>
              <li>
                <Link to="/giftcards">Gift Cards</Link>
              </li>
              <li>
                <Link to="/press"> Press Room</Link>
              </li>
            </ul>
          </div>
          <div className="col-one-third">
            <ul>
              <li>
                <a href="https://www.bmwusa.com/motorsport.html" rel="noopener noreferrer" target="_blank">
                  BMW Motorsport
                </a>
              </li>
              <li>
                <a href="https://www.bmwusa.com/" rel="noopener noreferrer" target="_blank">
                  BMWUSA.com
                </a>
              </li>
              <li>
                <a
                  href="https://www.bmwgroup-plants.com/spartanburg/en/plant-tours-zentrum-museum.html"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  BMW Zentrum
                </a>
              </li>
            </ul>
          </div>
          <div className="col-one-third">
            <p>Tag us! #BMWPerformanceDrivingSchool</p>
            <div className="socialmedia__links">
              <a
                href="https://www.instagram.com/bmwperformancedrivingschools"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="visit the bmw performance driving school instagram page (opens in a new window)"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" aria-label="Instagram Icon" role="img">
                  <title>BMW Performance Driving School Instagram Link</title>
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/bmwperformancecenter"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="visit the bmw performance driving school facebook page (opens in a new window)"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" aria-label="Facebook Icon" role="img">
                  <title>BMW Performance Driving School Facebook Link</title>
                  <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                </svg>
              </a>
              <a
                href="https://twitter.com/bmwpds"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="visit the bmw performance driving school twitter page (opens in a new window)"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" role="img" aria-label="Twitter Icon">
                  <title>BMW Performance Driving School Twitter Link</title>
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UC00pxApQ5mWAPNaL3idBAmg"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="visit the bmw performance driving school youtube page (opens in a new window)"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" role="img" aria-label="Youtube Icon">
                  <title>BMW Performance Driving School Youtube Link</title>
                  <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
                </svg>
              </a>
            </div>
            <p className="ultimate">
              The <span className="bold">Ultimate</span> Driving Machine<sup>®</sup>
            </p>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="list">
            <a className="footer__link" href="https://optout.aboutads.info/?c=2&lang=EN" target="_blank"
               rel="noopener noreferrer">
              Ad Choices
            </a>
            <a className="footer__link" href="https://www.bmwusa.com/privacy-policy/index.html" target="_blank"
               rel="noopener noreferrer">
              Privacy Policy
            </a>
            <a className="footer__link" href="https://www.bmwusa.com/content/dam/bmw/common/footer/pdf/BMW-Terms-20240613.pdf.asset.1720805387970.pdf" target="_blank"
               rel="noopener noreferrer">
              Terms
            </a>
            <a className="footer__link" href="javascript:OneTrust.ToggleInfoDisplay()">Do Not Sell or Share My Personal Information</a>
            <a className="footer__link" href="javascript:OneTrust.ToggleInfoDisplay()">Manage Cookies</a>
            <Link to="/siteMap" className="footer__link">Site Map</Link>
            <Link to="/schoolfaq" className="footer__link">Cancellation Policy</Link>
            <a className="footer__link" href="https://www.bmwusa.com/accessibility.html" target="_blank"
               rel="noopener noreferrer">
              BMW Accessibility Statement
            </a>
          </div>
          <div className="copyright">
            <span>© {new Date().getFullYear()} BMW of North America, LLC. The BMW name, BMW logo, model names, and other trademarks are trademarks of BMW AG.</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
